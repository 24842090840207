import styled from 'styled-components';

import { toRem } from 'utils/mixins';
import { white } from '../../theme/colors';

export const SwitchWrapper = styled.button`
  position: absolute;
  z-index: 6;
  background-color: ${white.default};
  top: 0;
  right: 0;
  padding: ${toRem(20)};

  svg {
    &:nth-last-child(1) {
      margin-left: ${toRem(20)};
    }
  }
`;
