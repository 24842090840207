import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import HTMLReactParser from 'html-react-parser';
import { Link } from 'gatsby';

import { Wrapper, Box } from './AmbGrid.styles';

const AmbGrid = (props) => {
  //PROPS
  const { data } = props;
  //PROPS

  return (
    <Wrapper>
      {data.map((slide, index) => {
        const image =
          slide.ambientes?.thumbnailImage?.localFile?.childImageSharp
            ?.gatsbyImageData;

        return (
          <Box>
            {image && (
              <GatsbyImage
                image={image}
                alt={slide.ambientes?.thumbnailImage?.altText}
              />
            )}
            <h2>{slide.ambientes?.nome}</h2>
            {slide.ambientes?.thumbnailText &&
              HTMLReactParser(slide.ambientes?.thumbnailText)}
            <Link className="button button--small" to={slide?.seo?.slug}>
              Ver mais
            </Link>
          </Box>
        );
      })}
    </Wrapper>
  );
};

export default AmbGrid;
