import React, { useState } from 'react';

import BannerSlider from 'components/slider';
import { Switch } from 'components/button';
import { graphql } from 'gatsby';
import AmbGrid from 'components/ambGrid';

const TemplateAmbientes = (props) => {
  //PROPS
  const {
    data: {
      allWpPost: { nodes },
    },
  } = props;
  //PROPS

  //STATES
  const [listMode, setMode] = useState(false);
  //STATES

  return (
    <>
      <Switch
        mode={listMode}
        func={() => {
          setMode(!listMode);
        }}
      />
      {listMode ? (
        <AmbGrid data={nodes} isAmb />
      ) : (
        <BannerSlider data={nodes} isAmb />
      )}
    </>
  );
};

export const query = graphql`
  query Ambientes($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      products {
        introText
      }
    }
    allWpPost {
      nodes {
        seo {
          slug
        }
        ambientes {
          thumbnailText
          nome
          thumbnailImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default TemplateAmbientes;
