import styled from 'styled-components';

import { container } from 'theme/sizes';
import { toRem } from 'utils/mixins';
import { white, black } from 'theme/colors';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${container.default};
  margin: 0 auto;
  width: calc(100% - 2.5rem);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Box = styled.div`
  margin-bottom: 40px;
  width: calc(50% - 40px);
  background-color: ${white.default};

  .gatsby-image-wrapper {
    opacity: 1;
  }

  h2,
  p {
    color: ${black.default};
  }

  .button {
    display: inline-block;
    width: auto;
    margin-top: ${toRem(10)};
  }
`;
