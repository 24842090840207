import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripHorizontal,
  faArrowsAltH,
} from '@fortawesome/free-solid-svg-icons';

import { SwitchWrapper } from './Button.styles';
import { black } from '../../theme/colors';

export const Switch = (props) => {
  //PROPS
  const { mode, func } = props;
  //PROPS

  console.log(mode, func);

  return (
    <SwitchWrapper onClick={() => func()}>
      <FontAwesomeIcon
        icon={faArrowsAltH}
        color={black.default}
        size="2x"
        opacity={mode && 0.2}
      />
      <FontAwesomeIcon
        icon={faGripHorizontal}
        color={black.default}
        opacity={!mode && 0.2}
        size="2x"
      />
    </SwitchWrapper>
  );
};
